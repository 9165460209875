<template>
  <base-material-card
    icon="mdi-pen"
    title="Notes"
    color="secondary"
  >
    <v-timeline
      v-if="person.notes && person.notes.length"
      align-top
      dense
    >
      <v-timeline-item
        v-for="note in person.notes"
        :key="note.id"
        fill-dot
        large
      >
        <template v-slot:icon>
          <v-avatar v-if="note.created_user !== null">
            <img :src="note.created_user.profile_pic">
          </v-avatar>
          <v-icon
            v-else
            dark
          >
            mdi-account
          </v-icon>
        </template>

        <v-card class="pa-6">
          <v-chip
            color="primary"
            class="text-overline mb-3"
            small
          >
            By <span v-if="note.created_user === null">System</span><span v-else>{{ note.created_user.username }}</span>
          </v-chip>
          <p
            class="text-text-subtitle-1"
            v-text="note.note"
          />
          <div
            class="text-text-body-2 d-flex justify-space-between align-center"
          >
            <span>{{ note.created_at ? moment(note.created_at).startOf('hour').fromNow() : '' }}</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  fab
                  small
                  color="error"
                  :loading="deleting && index === note.id"
                  @click="deleteNote(note.id); index = note.id"
                  v-on="on"
                >
                  <v-icon>mdi-note-remove</v-icon>
                </v-btn>
              </template>
              <span>Delete Note</span>
            </v-tooltip>
          </div>
        </v-card>
      </v-timeline-item>
    </v-timeline>

    <h4
      v-else
      class="text-center"
    >
      No Notes added yet
    </h4>
    <v-btn
      fab
      absolute
      bottom
      right
      small
      :loading="adding"
      @click="addNoteDlg = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog
      v-model="addNoteDlg"
      max-width="600"
    >
      <v-form
        ref="newNoteForm"
        lazy-validation
        @submit.prevent="addNote"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Add Note</span>
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model="newNote.note"
              label="Note *"
              :rules="[v => !!v || 'Note is required']"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              type="submit"
              :loading="adding"
            >
              Add Note
            </v-btn>
            <v-btn
              color="error"
              @click="addNoteDlg = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </base-material-card>
</template>

<script>
  import moment from 'moment'
  import { Api } from '@/apis'
  import { mapMutations, mapState } from 'vuex'

  export default {
    props: {
      fromAgent: {
        default: false,
        type: Boolean,
      },
    },

    data: () => ({
      addNoteDlg: false,
      adding: false,
      deleting: false,
      newNote: {},
      index: -1,
      moment,
    }),

    computed: {
      ...mapState({
        customer: state => state.items.customer,
        agent: state => state.items.agent,
      }),

      person () {
        return this.fromAgent ? this.agent : this.customer
      },
    },

    methods: {
      ...mapMutations([
        'SET_DYNAMIC_ITEM',
      ]),

      async addNote () {
        if (this.$refs.newNoteForm.validate()) {
          this.adding = true
          const response = await new Api().post({
            url: 'customer/note',
            request: {
              ...this.newNote,
              id: this.person.id,
              user: this.person.user,
            },
          })

          if (response) {
            this.addNoteDlg = false
            this.$refs.newNoteForm.reset()
          }
          this.adding = false
        }
      },

      async deleteNote (id) {
        const decision = await this.$confirm('Are you sure you want to delete?', { title: 'Warning' })
        if (decision) {
          this.deleting = true
          const response = await new Api().delete('customer/note/' + id)
          if (response) {
            const notes = this.person.notes.filter(note => note.id !== id)
            const agent = { ...this.person, notes }
            this.SET_DYNAMIC_ITEM({ data: { state: 'agent', item: agent } })
          }
          this.deleting = false
        }
      },
    },
  }
</script>
